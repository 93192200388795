import httpCommon from '@/plugins/http-common'

class RobotData {
  getAll () {
    return httpCommon.get('robots/')
  }

  get (id) {
    return httpCommon.get(`robots/${id}`)
  }

  create (data) {
    return httpCommon.post('robots/register/create/', data)
  }

  update (id, data) {
    return httpCommon.patch(`/robots/${id}`, data)
  }

  delete (id) {
    return httpCommon.delete(`/robots/${id}`)
  }
}

export default new RobotData()
