import axios from 'axios'
import store from '@/store'
import router from '@/router'

const httpCommon = axios.create({})

httpCommon.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
httpCommon.CancelToken = axios.CancelToken
httpCommon.isCancel = axios.isCancel

httpCommon.defaults.baseURL = process.env.VUE_APP_SERVER_URL || 'https://rb-dev.api.sirsteward.com'
httpCommon.defaults.withCredentials = false

/*
 * The interceptor here ensures that we check for the token in local storage every time an httpCommon request is made
 */
httpCommon.interceptors.response.use((response) => { // intercept the global error
  //console.log(store.getters.StateToken)
  httpCommon.defaults.headers['X-ACCESS-TOKEN'] = store.getters.StateToken
  // const token = store.getters.StateToken
  // if (token) {
  //   response.headers['X-ACCESS-TOKEN'] = token
  // }
  return response
}, function (error) {
  const originalRequest = error.config
  if (error.response.status === 401 && !originalRequest._retry) { // if the error is 401 and hasent already been retried
    originalRequest._retry = true // now it can be retried
    originalRequest.headers['X-ACCESS-TOKEN'] = store.getters.StateToken // new header new token
    httpCommon.defaults.headers['X-ACCESS-TOKEN'] = store.getters.StateToken
    return httpCommon(originalRequest) // retry the request that errored out
  }
  if (error.response.status === 401) {
    store.dispatch('logout')
    router.push('/login')
  }
  if (error.response.status === 404 && !originalRequest._retry) {
    originalRequest._retry = true
    window.location.href = '/'
    return
  }
  // Do something with response error
  return Promise.reject(error)
})

export default httpCommon
