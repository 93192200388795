<template>
  <v-container
    id="robots-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="green"
      icon="mdi-robot"
      inline
      class="px-5 py-3"
    >
      <template #after-heading>
        <div class="text-h3 font-weight-light">
          Robots
        </div>
      </template>

      <v-data-table
        :headers="computedHeaders"
        :items="computedItems"
        :search.sync="search"
        :sort-by="['serial_number', 'client']"
        :sort-desc="[false, true]"
        multi-sort
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-items>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                class="ml-auto"
                label="Search"
                hide-details
                single-line
                style="max-width: 250px;"
              />
            </v-toolbar-items>
            <v-toolbar-items>
              <v-select
                v-model="userId"
                clearable
                :label="$t('users')"
                :item-text="item => item.full_name +' - '+ item.email"
                item-value="_id"
                :items="$store.getters.users"
              />
            </v-toolbar-items>

            <v-spacer />

            <v-dialog
              v-model="dialog"
              fullscreen
              scrollable
              persistent
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="$store.getters.isAdmin"
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="addItem()"
                >
                  Add Robot
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="pa-0">
                  <v-toolbar
                    dark
                    color="primary"
                  >
                    {{ formTitle }}
                  </v-toolbar>
                </v-card-title>

                <v-card-text class="px-0 py-0">
                  <v-container
                    fluid
                    class="px-0 py-0"
                  >
                    <v-tabs
                      v-model="tab"
                      dark
                      icons-and-text
                    >
                      <v-tabs-slider />

                      <v-tab href="#tab-general">
                        General
                        <v-icon>mdi-account</v-icon>
                      </v-tab>
                      <v-tab href="#tab-location">
                        Location
                        <v-icon>mdi-security</v-icon>
                      </v-tab>
                      <v-tab href="#tab-debug">
                        Debug
                        <v-icon>mdi-bug</v-icon>
                      </v-tab>
                      <v-tab href="#tab-configs">
                        Configs
                        <v-icon>mdi-bug</v-icon>
                      </v-tab>
                      <v-tab href="#tab-routes">
                        Routes
                        <v-icon>mdi-bug</v-icon>
                      </v-tab>
                      <v-tab href="#tab-configs">
                        Configs
                        <v-icon>mdi-bug</v-icon>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item value="tab-general">
                        <v-row class="px-5">
                          <v-col
                            v-if="$store.getters.isAdmin"
                            cols="12"
                          >
                            <v-select
                              v-model="editedItem.user_id"
                              :items="$store.getters.users"
                              item-text="email"
                              item-value="_id"
                              label="User"
                              required
                              :rules="[(v) => !!v || 'User is required']"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.client_name"
                              label="Client Name"
                              :rules="[(v) => !!v || 'Client Name is required']"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.serial_number"
                              label="Serial Number"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editedItem.description"
                              label="Description"
                            />
                          </v-col>
                        </v-row>
                      </v-tab-item>

                      <v-tab-item value="tab-configs">
                        <v-row class="px-5">
                          <v-col
                            v-if="$store.getters.isAdmin"
                            cols="12"
                          >
                            <v-select
                              v-model="editedItem.user_id"
                              :items="$store.getters.users"
                              item-text="email"
                              item-value="_id"
                              label="User"
                              required
                              :rules="[(v) => !!v || 'User is required']"
                            />
                            <RobotConfigs :data="robot_document.robot_configs" />
                            <!--p>{{ robot_document.robot_configs }}</p-->
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.client_name"
                              label="Client Name"
                              :rules="[(v) => !!v || 'Client Name is required']"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.serial_number"
                              label="Serial Number"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editedItem.description"
                              label="Description"
                            />
                          </v-col>
                        </v-row>
                      </v-tab-item>

                      <v-tab-item value="tab-location">
                        <v-row class="px-5">
                          <v-col cols="12">
                            <vue-google-autocomplete
                              id="map"
                              ref="address"
                              classname="form-control"
                              placeholder="Please type your address"
                              country="ca"
                              autocomplete-text=""
                              @placechanged="getAddressData"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.address_1"
                              label="Address"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.address_2"
                              label="Address"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.city"
                              label="City"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.postal_code"
                              label="Postal Code"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.province"
                              label="Province"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.country"
                              label="Country"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.longitude"
                              label="Longitude"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.latitude"
                              label="Latitude"
                            />
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item
                        value="tab-debug"
                        class="px-5"
                      >
                        <v-row class="px-5">
                          <v-col cols="12"
                          >
                            <pre>{{ editedItem }}</pre>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    text
                    @click="save"
                  >
                    Save
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template #item.pin_number="{ item }">
          <span class="blur">{{ item.pin_number }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-btn
            v-for="(action, i) in $data.actions"
            :key="i"
            class="px-2 ml-1"
            :color="action.color"
            min-width="0"
            small
            :title="action.title"
            @click="item._id"
          >
            <v-icon
              small
              v-text="action.icon"
            />
          </v-btn>
        </template>
        <template #item.client="{ item }">
          <b>{{ item.client_name }}</b><br>
          {{ item.address_1 }}<br>
          {{ item.address_2 }}<br>
          {{ item.city }}, {{ item.province }}<br>
          {{ item.postal_code }}
        </template>
        <template #item.is_online="{ item }">
          <v-icon
            v-if="item.is_online"
            color="green"
          >
            mdi-lan-connect
          </v-icon>
          <v-icon
            v-else
            color="red"
          >
            mdi-lan-disconnect
          </v-icon>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="$store.getters.isAdmin"
            color="blue"
            small

            @click="editItem(item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
            v-if="$store.getters.isAdmin"
            color="red"
            small
            @click="deleteItem(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <template #no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>

  import RobotData from '@/data/robots'
  import RobotConfigsData from '@/data/robot-configs'
  import { mapActions } from 'vuex'

  import { validationMixin } from 'vuelidate'
  import { email, maxLength, required } from 'vuelidate/lib/validators'
  import VueGoogleAutocomplete from 'vue-google-autocomplete2'
  import RobotConfigs from '../../components/robot_configs/base.vue'

  export default {
    name: 'Robots',
    components: {
      VueGoogleAutocomplete, RobotConfigs
    },
    mixins: [validationMixin],
    validations: {
      name: {
        required,
        maxLength: maxLength(10)
      },
      email: {
        required,
        email
      },
      select: { required },
      checkbox: {
        checked (val) {
          return val
        }
      }
    },
    data: () => ({

      userId: false,
      valid: false,
      tab: null,
      dialog: false,
      dialogDelete: false,
      loading: true,
      // users: [],
      editedIndex: -1,
      robot_document: {},
      editedItem: {
        user_id: '',
        serial_number: '',
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        postal_code: '',
        province: 'QC',
        country: 'Canada',
        longitude: '',
        latitude: ''
      },
      defaultItem: {
        name: '',
        province: 'QC',
        country: 'Canada'
      },

      actions: [
        {
          title: 'account',
          color: 'info',
          icon: 'mdi-account'
        },
        {
          title: 'Edit',
          color: 'success',
          icon: 'mdi-pencil'
        },
        {
          title: 'Delete',
          color: 'error',
          icon: 'mdi-close'
        }
      ],
      headers:
        [
          {
            text: 'User',
            value: 'user.full_name',
            isAdmin: true

          },
          {
            text: 'Serial Number',
            value: 'serial_number'
          },
          {
            text: 'PIN Number',
            value: 'pin_number'
          },
          {
            text: 'Client',
            value: 'client',
            class: 'text-nowrap'
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Online',
            value: 'is_online',
            align: 'center'
          },
          {
            sortable: false,
            text: 'Actions',
            value: 'actions',
            class: 'text-nowrap'
          }
        ],
      items:
        [],
      search:
        undefined
    }
    ),
    computed: {

      computedItems () {
        const p = this
        const res = this.items.filter(function (el) {
          return (!p.userId || (p.userId && el.user_id === p.userId))
        })
        return res
      },

      computedHeaders () {
        const p = this
        return this.headers.filter(function (el) {
          if (el.value === 'user.full_name' && p.userId) return false
          return (p.$store.getters.isAdmin && el.isAdmin) || !el.isAdmin
        })
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Robot' : 'Edit Robot'
      }

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }

    },
    created () {
      this.initialize()
    },
    mounted: function () {
      this.listRobots()
      this.getUsers()
    },
    methods: {
      ...mapActions(['getUsers']),

      isExist (action) {
        var res = this.hooks.find(hook => hook.name === action)
        return !!res
      },
      isEnabled (hook, section) {
        var res = this.editedItem.actions.find(item => item.name === hook)
        return !!((res[section] && res[section].enabled))
      },
      editDoor (door) {
        console.dir(door)
      },
      getAddressData (addressData, placeResultData, id) {
        this.editedItem.address_1 = addressData.street_number
        this.editedItem.address_2 = addressData.route
        this.editedItem.city = addressData.locality
        this.editedItem.postal_code = addressData.postal_code
        this.editedItem.latitude = addressData.latitude
        this.editedItem.longitude = addressData.longitude
        this.editedItem.province = addressData.administrative_area_level_1

        console.dir(addressData)
      },
      getIndex (item, action) {
        const res = item.find(i => i.action === action)
        if (!res.questions) res.questions = false
        return res
      },
      listRobots (userId) {
        RobotData.getAll()
          .then(response => {
            this.items = response.data

            if (userId) {
              this.items = response.data.filter(function (el) {
                return el.user_id === userId
              })
            }
            this.loading = false
          })
          .catch(e => {
            console.log(e)
          })
      },
      listConfigs () {
        var robot_id = this.editedItem.user_id
        RobotConfigsData.get(robot_id)
          .then(response => {
            this.robot_document = response.data
          })
          .catch(e => {
            console.log(e)
          })
      },
      initialize () {
        // console.log('INIT')
      },
      itemRowBackground: function (item) {
        console.dir(item)
        return item.is_online ? 'green' : 'red'
      },
      addItem () {
        this.editedItem = Object.assign({}, this.defaultItem)
      },
      editItem (item) {
        this.editedIndex = this.items.indexOf(item)

        this.editedItem = Object.assign({}, this.defaultItem, item)
        this.dialog = true
        this.listConfigs()
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.$swal({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            RobotData.delete(this.editedItem._id)
              .then(response => {
                this.$swal(
                  'Deleted!', 'The robot has been deleted.', 'success'
                )
                this.closeDelete()
                this.listRobots()
              })
          }
        })
        // this.dialogDelete = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.tab = null
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.tab = null
        })
      },

      save () {
        // if (!this.valid) return false
        //var config_to_send = { Configs:"" }
        //config_to_send.Configs = this.robot_document
        delete this.robot_document.robot_id
        var test = RobotConfigsData.update(this.editedItem.user_id, {"Configs":this.robot_document})

        /*if (this.editedItem._id) {
          delete this.editedItem.software_version

          RobotData.update(this.editedItem._id, this.editedItem)
            .then(response => {
              // console.log(response.data)
              this.close()
              this.listRobots()
            })
        } else {
          RobotData.create(this.editedItem)
            .then(response => {
              console.log(response.data)
              this.close()
              this.listRobots()
            })
        }*/
      }

    }

  }
</script>
<style scoped lang="scss" src="./Robots.scss"></style>
