import httpCommon from '@/plugins/http-common'

class RobotConfigsData {
  getAll () {
    return httpCommon.get('robot_configs/')
  }

  get (id) {
    return httpCommon.get(`configs/${id}`)
  }

  create (data) {
    return httpCommon.post('robot_configs/register/create/', data)
  }

  update (id, data) {
    httpCommon.patch(`updates/robot_datas/${id}`, {"robot_configs": true})
    return(httpCommon.patch(`configs/robot_configs/${id}`, data))
  }

  delete (id) {
    return httpCommon.delete(`/robot_configs/${id}`)
  }
}

export default new RobotConfigsData()
