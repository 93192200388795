<template>
  <div>
    <div v-for="modules_configs, modules_names in data" :key="modules_configs.key">
      <ConfigValues :configValues="modules_configs" :modName="modules_names"></ConfigValues>
    </div>
  </div>
</template>

<script>

import ConfigValues from "./values.vue";

export default{
  name: 'RobotConfigs',
  props: ['data'],
  components: { ConfigValues },
  setup(){
    return {}
  }
}

</script>

<style></style>