<template>
  <div>
    <h1 class="title-names">{{modName}}</h1>
    <h1 class="title-values">Values</h1>

    <div style="width:fit-content;" v-for="conf_val, conf_name in configs_string" :key="conf_name">
      <li class="config-names">{{ conf_name }}</li>
      <input class="config-values" type="text" v-model="configValues[conf_name]">
    </div>

    <div style="width:fit-content;" v-for="conf_val, conf_name in configs_number" :key="conf_name">
      <li class="config-names">{{ conf_name }}</li>
      <input class="config-values" type="number" v-model.number="configValues[conf_name]">
    </div>

    <div style="width:fit-content;" v-for="conf_val, conf_name in configs_bool" :key="conf_name">
      <li class="config-names">{{ conf_name }}</li>
      <input class="config-values" type="checkbox" v-model="configValues[conf_name]">
    </div>

  </div>
</template>

<script>
import e from "trix";
import { ref } from "vue";

export default{
  name: 'ConfigValues',
  props:['configValues', 'modName'],
  setup(props){
    var configs_string = ref({})
    var configs_number = ref({})
    var configs_bool = ref({})
    //var val = ""
    var type = ""

    for (let key in props.configValues){
      const val = props.configValues[key]
      type = typeof val

      if (type == "string"){
        configs_string.value[key] = val
      }
      else if (type == "number"){
        configs_number.value[key] = val
      }
      else if (type == "boolean"){
        configs_bool.value[key] = val
      }
    }

    const handleClick = (c) => {
      console.log(c)
    }

    return {configs_string, 
            configs_number, 
            configs_bool,
            handleClick
            }
  }
}

</script>

<style scoped lang="scss" src="./values.scss"></style>