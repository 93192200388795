<template>
  <robots />
</template>

  <script>
  import { ref } from 'vue'
  import router from '../router'
  import robots from '../views/robots/Robots.vue'

  export default {
    name: 'RobotVue',
    components: {
      robots
    },
    setup () {
      const robots = ref(10)

      const onClick = () => {
        router.push({ name: 'Robots' })
      }

      return {
        robots,
        onClick
      }
    }
  }
  </script>
