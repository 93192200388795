module.exports = {
  vueform: {
    elements: {
      list: {
        add: '+ Ajouter',
        remove: '&times;',
      },
      file: {
        defaultName: 'Fichier',
        dndTitle: 'Téléversez le fichier',
        dndDescription: 'Déposez le ficher ou cliquez ici pour le téléverser.',
        removeConfirm: 'En supprimant le fichier, il sera définitivement effacé. Êtes-vous sûr de vouloir procéder ?',
        select: 'Sélectionner  les fichiers',
        upload: 'Téléverser',
      },
      multifile: {
        uploadButton: 'Téléverser les fichiers',
        dndTitle: 'Téléverser les fichiers',
        dndDescription: 'Déposer les fichiers ou cliquer ici pour les téléverser',
      },
      gallery: {
        uploadButton: 'Téléverser des images',
        dndTitle: 'Téléverser des images',
        dndDescription: 'Glisser des images ou cliquer ici pour les téléverser',
      },
    },
    steps: {
      finish: 'Terminer',
      next: 'Suivant',
      previous: 'Précédent',
    },
    editor: {
      acceptedMimesError: 'Les mimes acceptés sont: :mimes',
      acceptedExtensionsError: 'Les extensions acceptées sont: :extensions',
    },
    multiselect: {
      multipleLabelOne: '1 option sélectionnée',
      multipleLabelMore: ':options options sélectionnées',
      noResults: 'Aucunes options trouvées',
      noOptions: 'La liste est vide',
    },
    defaultMessage: 'Champ invalide',
    dateFormats: {
      datetimeSeconds24: 'DD/MM/YYYY, HH:mm:ss',
      datetimeSeconds12: 'DD/MM/YYYY, hh:mm:ss a',
      datetime24: 'DD/MM/YYYY, HH:mm',
      datetime12: 'DD/MM/YYYY, hh:mm a',
      timeSeconds24: 'HH:mm:ss',
      timeSeconds12: 'hh:mm:ss a',
      time24: 'HH:mm',
      time12: 'hh:mm a',
      date: 'DD/MM/YYYY',
    },
  },
  validation: {
    accepted: 'Le champ :attribute doit être accepté.',
    active_url: 'Le champ :attribute n\'est pas une URL valide.',
    after: 'Le champ :attribute doit être une date postérieure au :date.',
    after_or_equal: 'Le champ :attribute doit être une date postérieure ou égale au :date.',
    alpha: 'Le champ :attribute doit contenir uniquement des lettres.',
    alpha_dash: 'Le champ :attribute doit contenir uniquement des lettres, des chiffres et des tirets.',
    alpha_num: 'Le champ :attribute doit contenir uniquement des chiffres et des lettres.',
    array: 'Le champ :attribute doit être un tableau.',
    before: 'Le champ :attribute doit être une date antérieure au :date.',
    before_or_equal: 'Le champ :attribute doit être une date antérieure ou égale au :date.',
    between: {
      numeric: 'La valeur de :attribute doit être comprise entre :min et :max.',
      file: 'La taille du fichier de :attribute doit être comprise entre :min et :max kilo-octets.',
      string: 'Le texte :attribute doit contenir entre :min et :max caractères.',
      array: 'Le tableau :attribute doit contenir entre :min et :max éléments.',
    },
    boolean: 'Le champ :attribute doit être vrai ou faux.',
    confirmed: 'Le champ de confirmation :attribute ne correspond pas.',
    date: 'Le champ :attribute n\'est pas une date valide.',
    date_equals: 'Le champ :attribute doit être une date égale à :date.',
    date_format: 'Le champ :attribute ne correspond pas au format :format.',
    different: 'Les champs :attribute et :other doivent être différents.',
    digits: 'Le champ :attribute doit contenir :digits chiffres.',
    digits_between: 'Le champ :attribute doit contenir entre :min et :max chiffres.',
    dimensions: 'La taille de l\'image :attribute n\'est pas conforme.',
    distinct: 'Le champ :attribute a une valeur en double.',
    email: 'Le champ :attribute doit être une adresse email valide.',
    ends_with: 'Le champ :attribute doit se terminer par une des valeurs suivantes : :values',
    exists: 'Le champ :attribute sélectionné est invalide.',
    file: 'Le champ :attribute doit être un fichier.',
    filled: 'Le champ :attribute doit avoir une valeur.',
    gt: {
      numeric: 'La valeur de :attribute doit être supérieure à :value.',
      file: 'La taille du fichier de :attribute doit être supérieure à :value kilo-octets.',
      string: 'Le texte :attribute doit contenir plus de :value caractères.',
      array: 'Le tableau :attribute doit contenir plus de :value éléments.',
    },
    gte: {
      numeric: 'La valeur de :attribute doit être supérieure ou égale à :value.',
      file: 'La taille du fichier de :attribute doit être supérieure ou égale à :value kilo-octets.',
      string: 'Le texte :attribute doit contenir au moins :value caractères.',
      array: 'Le tableau :attribute doit contenir au moins :value éléments.',
    },
    image: 'Le champ :attribute doit être une image.',
    in: 'Le champ :attribute est invalide.',
    in_array: 'Le champ :attribute n\'existe pas dans :other.',
    integer: 'Le champ :attribute doit être un entier.',
    ip: 'Le champ :attribute doit être une adresse IP valide.',
    ipv4: 'Le champ :attribute doit être une adresse IPv4 valide.',
    ipv6: 'Le champ :attribute doit être une adresse IPv6 valide.',
    json: 'Le champ :attribute doit être un document JSON valide.',
    lt: {
      numeric: 'La valeur de :attribute doit être inférieure à :value.',
      file: 'La taille du fichier de :attribute doit être inférieure à :value kilo-octets.',
      string: 'Le texte :attribute doit contenir moins de :value caractères.',
      array: 'Le tableau :attribute doit contenir moins de :value éléments.',
    },
    lte: {
      numeric: 'La valeur de :attribute doit être inférieure ou égale à :value.',
      file: 'La taille du fichier de :attribute doit être inférieure ou égale à :value kilo-octets.',
      string: 'Le texte :attribute doit contenir au plus :value caractères.',
      array: 'Le tableau :attribute doit contenir au plus :value éléments.',
    },
    max: {
      numeric: 'La valeur de :attribute ne peut être supérieure à :max.',
      file: 'La taille du fichier de :attribute ne peut pas dépasser :max kilo-octets.',
      string: 'Le texte de :attribute ne peut contenir plus de :max caractères.',
      array: 'Le tableau :attribute ne peut contenir plus de :max éléments.',
    },
    mimes: 'Le champ :attribute doit être un fichier de type : :values.',
    mimetypes: 'Le champ :attribute doit être un fichier de type : :values.',
    min: {
      numeric: 'La valeur de :attribute doit être supérieure ou égale à :min.',
      file: 'La taille du fichier de :attribute doit être supérieure à :min kilo-octets.',
      string: 'Le texte :attribute doit contenir au moins :min caractères.',
      array: 'Le tableau :attribute doit contenir au moins :min éléments.',
    },
    not_in: 'Le champ :attribute sélectionné n\'est pas valide.',
    not_regex: 'Le format du champ :attribute n\'est pas valide.',
    numeric: 'Le champ :attribute doit contenir un nombre.',
    password: 'Le mot de passe est incorrect',
    present: 'Le champ :attribute doit être présent.',
    regex: 'Le format du champ :attribute est invalide.',
    required: 'Le champ :attribute est obligatoire.',
    required_if: 'Le champ :attribute est obligatoire quand la valeur de :other est :value.',
    required_unless: 'Le champ :attribute est obligatoire sauf si :other est :values.',
    required_with: 'Le champ :attribute est obligatoire quand :values est présent.',
    required_with_all: 'Le champ :attribute est obligatoire quand :values sont présents.',
    required_without: 'Le champ :attribute est obligatoire quand :values n\'est pas présent.',
    required_without_all: 'Le champ :attribute est requis quand aucun de :values n\'est présent.',
    same: 'Les champs :attribute et :other doivent être identiques.',
    size: {
      numeric: 'La valeur de :attribute doit être :size.',
      file: 'La taille du fichier de :attribute doit être de :size kilo-octets.',
      string: 'Le texte de :attribute doit contenir :size caractères.',
      array: 'Le tableau :attribute doit contenir :size éléments.',
    },
    string: 'Le champ :attribute doit être une chaîne de caractères.',
    timezone: 'Le champ :attribute doit être un fuseau horaire valide.',
    unique: 'La valeur du champ :attribute est déjà utilisée.',
    uploaded: 'Le fichier du champ :attribute n\'a pu être téléversé.',
    url: 'Le format de l\'URL de :attribute n\'est pas valide.',
    uuid: 'Le champ :attribute doit être un UUID valide',
    remote: 'Le champ :attribute n\'est pas valide.',
  },
}
