import httpCommon from '@/plugins/http-common'

export default {
  state: {
    robots: {},
    users: {}
  },
  getters: {
    users: state => state.users,
    getUserByID: function (state, id) {
      return state.users.find(obj => {
        return obj._id === id
      })
    },

    robots: state => state.robots,
    getRobotByID: function (state) {
      return (id) => {
        return state.robots.find(obj => obj._id === id)
      }
    }

  },
  mutations: {
    SET_USERS (state, payload) {
      state.users = payload
    },

    SET_DEVICES (state, payload) {
      state.robots = payload
    }

  },
  actions: {
    async getUsers ({ commit }) {
      return httpCommon.get('data/users/').then(response => {
        commit('SET_USERS', response.data)
      })
    },
    async getRobots ({ commit }) {
      return httpCommon.get('data/robots/').then(response => {
        commit('SET_DEVICES', response.data)
      })
    }
  }
}
