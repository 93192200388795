<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    @click="onClick"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-robot"
          title="Robots"
          :value="robots"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { ref } from 'vue'
  import router from '../router'

  export default {
    name: 'DashboardDashboard',
    setup () {
      const robots = ref(10)

      const onClick = () => {
        router.push({ name: 'Robots' })
      }

      return {
        robots,
        onClick
      }
    }
  }
</script>
