var render, staticRenderFns
import script from "./FilePreview_image.vue?vue&type=script&lang=js&"
export * from "./FilePreview_image.vue?vue&type=script&lang=js&"
import style0 from "./FilePreview_image.vue?vue&type=style&index=0&id=787149ce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports