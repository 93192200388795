import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import DashboardPage from './pages/DashboardPage'
import RobotsPage from './pages/RobotsPage'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/account/Index'),
      children: [
        {
          name: 'Home',
          path: '/',
          component: () => import('@/views/account/Login'),
          meta: { requiresAuth: true }
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/account/Login'),
          meta: { guest: true }
        }
      ]
    },
    {
      path: '/admin/',
      component: () => import('@/views/dashboard/Index'),
      meta: { requiresAuth: true },
      children: [
        // Dashboard
        {
          path: '',
          component: DashboardPage
        },
        // Pages
        {
          path: 'robots',
          component: RobotsPage
        },
        {
          name: 'Users',
          path: 'users',
          component: () => import('@/views/dashboard/Users')
        },
        {
          name: 'Projects',
          path: 'projects',
          component: () => import('@/views/dashboard/Projects')
        },
        {
          name: 'Logs',
          path: 'logs',
          component: () => import('@/views/dashboard/Logs')
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
