import en from '@vueform/vueform/locales/en'
import fr from '@vueform/vueform/locales/fr_CA'
import vueform from '@vueform/vueform/themes/vueform'

export default {
  theme: vueform,
  locales: {
    en,
    fr
  },
  locale: 'en'
}
