import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, max, min, numeric, required } from 'vee-validate/dist/rules'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)

extend('password', {
  validate: function (value) {
    return value.match(/[0-9a-z]{8,}/) !== null
  },
  message: 'The password length must be 8 characters or greater.'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
